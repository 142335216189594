import React, { useEffect } from "react"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

const LogoListItem = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }
  return (
    <motion.li
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={controls}
      className="col-span-1 flex justify-center py-8 px-8"
    >
      <div className="w-full">
        <Img fluid={props.logo} />
      </div>
    </motion.li>
  )
}

export default LogoListItem
