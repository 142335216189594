import React, { useEffect } from "react"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import Img from "gatsby-image"

import { CarouselProvider, Slider, Slide } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
const LogoCarousel = props => {
  console.log(props)
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const variants = {
    visible: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    hidden: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  }

  const variantsText = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      className="inner py-12 px-4 sm:px-6 lg:py-16 lg:px-8 mt-20"
      id="clients"
    >
      <motion.p
        variants={variantsText}
        initial="hidden"
        animate={controls}
        className="text-center text-20 leading-6 font-bold text-black mb-8"
      >
        Our Clients
      </motion.p>

      <div className="max-w-4xl mx-auto">
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={120}
          visibleSlides={4}
          totalSlides={25}
          isPlaying={true}
          infinite={true}
          interval={3000}
        >
          <Slider>
            {props.logos.edges.map(logo => (
              <Slide index={0}>
                <div className="w-full" key={logo.node.id}>
                  <Img fluid={logo.node.childImageSharp.fluid} />
                </div>
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
      </div>
    </motion.div>
  )
}

export default LogoCarousel
