import React, { useEffect } from "react"

import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

import LogoListItem from "./logoListItem"

const LogoList = props => {
  // console.log(props)
  // const { logos } = useClientLogos()
  // console.log(logos)
  // console.log(useClientLogos())
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: "-100px 0px",
  })

  useEffect(() => {
    inView ? controls.start("visible") : controls.start("hidden")
  }, [controls, inView])

  const variants = {
    visible: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    hidden: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  }

  const variantsText = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      className="inner py-12 px-4 sm:px-6 lg:py-16 lg:px-8 mt-20"
    >
      <motion.p
        variants={variantsText}
        initial="hidden"
        animate={controls}
        className="text-center text-20 leading-6 font-bold text-black"
      >
        Accreditations
      </motion.p>

      <div className="max-w-4xl mx-auto grid grid-cols-3 gap-0.5">
        {props.logos.map(logo => (
          <LogoListItem logo={logo.childImageSharp.fluid} key={logo.id} />
        ))}
      </div>
    </motion.div>
  )
}

export default LogoList
